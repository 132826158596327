.carousel {
    margin-top: 36px;
    }

.App {
    text-align: center;
    background: url('brick-wall.jpeg');
    background-size: cover;
    min-height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
  }
  
  .titlesticky{
  margin: auto;
  padding-top: 50px;
  font-size: 44px;
  font-weight: bolder;
  text-transform: capitalize;
  letter-spacing: 8px;
  overflow:  hidden;


  }
  
  
  
  .main-form{
    display: flex;
    flex-direction: column;
   
  }
  .note textarea:focus {
    outline: none;
    border: none;
    box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.2) inset;
    }
  .note_cnt textarea{
    
    width: 100%;
    height: 100px;
    border: 0px solid lightgray;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  }
  
  .main-form button{
  background-color: yellow;
  font-size: 16px;
  font-weight: bold;
  width:205px;
  height:27px;
  border: none;
  outline: none;
  transition: transform 100ms ease-in-out;
  }
  
  .main-form button:hover{
    background-color: tomato;
    color: white;
    transform: scale(0.9);
  }
  
  .note{
    position: relative; 
    
   padding-left: 15%;
   padding-right: 15%;
   
    height: 200px; 
    width:100%;

  } 
  
  .text{
    color:orange;
    text-align: left;
    position: absolute;
    top:20px;
    left:20px;
  }
  
  .delete-btn{
    position: relative;
  
    margin-left: 85%;
    border-radius: 50%;
    border: none;
  
    color: white;
    height: 30px;
    width: 30px;
    font-weight: bold;
    transition: transform 400ms ease;
  }
  
  .delete-btn:hover{
 
    color:black;

  }
  
  .note-length{
    font-size: 24px;
    color: white;
  }
  
  