.progress-container {
    height: 25px;
    width: 100%;
    margin-bottom: 20px;
  
    .progress-step-container {
      max-width: 500px;
      width: 90%;
      top: 10px;
      margin: 0 auto;
      position: relative;
    }
  
    .progress-line-container {
      background-color: #d8dee5;
      height: 2px;
      width: 100%;
  
      .progress-line {
        background: #213846;
        height: 2px;
        width: 0;
        transition: all .2s linear;
      }
    }
  
    .progress-bullet-container {
      background: transparent;
      width: 0;
      height: 0;
      overflow: visible;
      position: relative;
      display: block;
  
      .progress-bullet {
          text-align: center;
        width: 22px;
        height: 22px;
        background: #d8dee5;
        border-radius: 22px;
        transform: translate(-10px,-10px);
        transition: all .4s linear;
      }
  
      .progress-bullet.active {
        background-color: #213846;
      }
    }
  }