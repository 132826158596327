.btn-custom {
margin-right: 10px;
margin-bottom: 10px;
    width: 210px;
    color: #fff;
    border-color: #2E4C80;
    border-color: cutom-cor;
    padding: 0.375rem 1.75rem;
    font-size: 14px;
    border-color: var(--theme-secondary) !important;
    display: inline-block;
    font-weight: 400;
text-align: center;
vertical-align: middle;
-webkit-user-select: none;
user-select: none;
border: 1px solid transparent;
padding: 0.375rem 0.75rem;
font-size: 1rem;
line-height: 1.5;
border-radius: 0.25rem;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}